<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <ASuccessFour :api="this.api"></ASuccessFour>
        <v-skeleton-loader ref="skeleton" type="table" v-if="api.isLoading"></v-skeleton-loader>
        <v-row class="mt-3" v-else>
            <v-col align="center" justify="center" cols="12" sm="8" class="mx-auto">
                <v-col align="start" justify="start">
                    <v-btn
                    @click="()=>{$router.go(-1)}"
                    class="mb-3"
                    color="primary">
                        <v-icon>
                            mdi-arrow-left-bold
                        </v-icon>
                    </v-btn>
                </v-col>
                <v-card class="pa-3" elevation-1>
                    <!--BOC : Create a form here-->
                    <v-toolbar class="secondary white--text text-h5">
                        Send Email
                    </v-toolbar>
                    <div class="mt-4 pt-3">
                        <div class="d-flex justify-start">
                            <v-autocomplete :items="client" item-text="email" return-object v-model="selectedClient" label="User" dense outlined>
                                
                            </v-autocomplete>
                        </div>
                        <div class="d-flex justify-start">
                            <v-autocomplete :items="emailOptions" item-text="text" return-object v-model="selectedEmail" dense outlined label="Email Template">

                            </v-autocomplete>
                        </div>
                        <div class="d-flex justify-end">
                            <v-btn color="primary" plain @click="()=>{
                                $router.go(-1);
                            }">
                                Cancel
                            </v-btn>
                            <v-btn :loading="api.isLoading" color="primary" @click="()=>{
                                isPending = true;
                            }">
                                Confirm
                            </v-btn>
                        </div>

                    </div>
                    <!--EOC-->
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import AConfirmation from '../../components/common/AConfirmation.vue';
import ASuccessFour from '../../components/common/ASuccessFour.vue';
export default {
    components:{
    AConfirmation,
    ASuccessFour,
},
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
        isPending:false,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            },
        client:null,
        selectedClient:null,
        selectedEmail:null,
        emailOptions:[
            {
                text:"Welcome email",
                value:4
            },
            {
                text:'Reset Password',
                value:1,
            },
            {
                text:'Verify Account',
                value:3
            },
        ]
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class==="getCompanyPICAndStaff"){
                this.client = (resp.data.client.length!=0)?resp.data.client:null;
            }
            if(resp.class==="sendEmail"){
                this.api.isSuccesful = true;
                this.api.success = "Succesfully send the email";
            }
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            let fetchStaffApi = this.fetchStaff();
            this.$api.fetch(fetchStaffApi);

        },
        fetchStaff(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/company/clientpic/"+this.$router.history.current.params.id;
            return tempApi;
        },
        validateInput(){
            this.isPending = true;
        },
        submit()
        {
            this.isPending = false;
            let sendEmailApi = this.sendEmail();
            this.$api.fetch(sendEmailApi);

        },
        sendEmail(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "POST";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/email/send";
            let formData = new FormData;
            formData.append('id',this.selectedClient.user_id);
            formData.append('template',this.selectedEmail.value);
            tempApi.params = formData;
            return tempApi;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        create(){

        },
    }
}
</script>